import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field from '../../Field';

import css from './SectionContainer.module.css';

// This component can be used to wrap some common styles and features of Section-level components.
// E.g: const SectionHero = props => (<SectionContainer><H1>Hello World!</H1></SectionContainer>);
const SectionContainer = props => {
  const {
    className,
    rootClassName,
    id,
    as,
    children,
    appearance,
    options,
    sectionName,
    ...otherProps
  } = props;
  const Tag = as || 'section';
  const isBoxed = /Boxed/.test(sectionName);
  const noPaddingTop = /NoPaddingTop/.test(sectionName);
  const isSlim = /Slim/.test(sectionName);
  const classes = classNames(rootClassName || css.root, className, {
    [css.containerBoxed]: isBoxed,
    [css.containerNoPaddingTop]: noPaddingTop,
  });
  const customAppearanceClasses = classNames(className, {
    [css.contentBoxed]: isBoxed,
    [css.contentNoPaddingTop]: noPaddingTop,
  });
  const sectionContentClasses = classNames(css.sectionContent, {
    [css.boxedParagraph]: isBoxed,
    [css.contentSlim]: isSlim,
  });

  return (
    <Tag className={classes} id={id} {...otherProps}>
      {appearance?.fieldType === 'customAppearance' ? (
        <Field
          data={{ alt: `Background image for ${id}`, ...appearance }}
          className={customAppearanceClasses}
          options={options}
        />
      ) : null}

      <div className={sectionContentClasses}>{children}</div>
    </Tag>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionContainer.defaultProps = {
  rootClassName: null,
  className: null,
  as: 'section',
  children: null,
  appearance: null,
  sectionName: null,
};

SectionContainer.propTypes = {
  rootClassName: string,
  className: string,
  as: string,
  children: node,
  appearance: object,
  options: propTypeOption,
  sectionName: string,
};

export default SectionContainer;
